body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: #0088EB; */
  /* color: white; */
}

code {
  font-family: 'Source Code Pro', monospace;
  background: #F6F6F6;
  display: block;
  padding: 0.5rem;
}